import Compressor from 'compressorjs'
import {mapActions,mapGetters} from "vuex"

export default {
  data(){
    return{
      petText:'견',
      msg:'종을 선택해주세요.',
      
      imageMsg: null,

      breedIdx: 0,
      breed: null,
      breedName: null,
      name: null,
      birthday : '',
      sex :null,
      sexList :[
        {code:'M', text:'남아'},
        {code:'F', text:'여아'},
      ],

      size:null,
      sizeList :[
        {code:'S', text:'소형'},
        {code:'M', text:'중형'},
        {code:'L', text:'대형'},
      ],
      weight:null,
      petWeight1:null,
      petWeight2:null,
      
      neuter :null,
      neuterList :[
        {code:0, text:'중성화 전'},
        {code:1, text:'중성화 후'},
      ],
      
      keyword: [],
      disease:[],
      allergicYN :null,
      allergic: [],
      
      profileData: null,
      profileUrl: null,
      imgName: null,

      isOpenBreedPopup:false,

      rules: {
        breedName: [v => !!v || this.$msg('required.input')],
        name: [v => !!v || this.$msg('required.input'), v => v.patternCheck('petName') || this.$msg('petname.check')],
        birthday: [v => !!v || this.$msg('required.input'), v => v.patternCheck('yyyymmdd') || this.$msg('yyyymmdd.check'), v => !this.checkBirthday(v) || this.$msg('untilToday.check')],
      },
    }
  },
  watch: {
    petWeight2(v) {
      if (!this.petWeight1) this.petWeight1 = 0
    },
  },
  computed: {
    ...mapGetters({
      getKeywordCode: 'common/getKeywordCode',
      getDiseaseCode: 'common/getDiseaseCode',
      getAllergyCode: 'common/getAllergyCode',
    }),
    isAllergic(){
      if(this.allergicYN == 1){
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      setPet: 'session/setPet',
    }),
    checkBirthday(v) {

      const today = new Date()
      let y = today.getFullYear()
      let m = today.getMonth() + 1
      let d = today.getDate()
      
      if (m.toString().length == 1) m = "0" + m
      if (d.toString().length == 1) d = "0" + d
      
      return Number(v) > Number(`${y}${m}${d}`)
    },
    setCode(type, code) {
      this[type] = code
    },
    setBreed(data){
      this.closeBreedPopup()
      if (data.idx) {
        this.breedIdx = data.idx
        this.breed = data.name
        this.breedName = null
      } else{
        this.breedIdx = null
        this.breed = '직접입력'
        setTimeout(() => {
          this.$refs.breedName.focus()
        }, 100)
      }
    },
    async uploadImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.imageMsg = this.$msg('image.ext')
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt)
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.imageMsg = this.$msg('image.limit')
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      vm.profileData = reader.result
                      vm.imgName = _file.name
                      vm.profileUrl = URL.createObjectURL(_file)
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  vm.profileData = reader.result
                  vm.imgName = file.name
                  vm.profileUrl = URL.createObjectURL(file)
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    openBreedPopup() {
      document.body.style.overflowY ="hidden"
      this.isOpenBreedPopup = true
    },
    closeBreedPopup() {
      document.body.style.overflowY ="scroll"
      this.isOpenBreedPopup = false
    },
    async save() {
      const { result } = await this.$api.setPets({
        idx  : this.idx,
        petCode  : this.petCode,
        breedIdx  : this.breedIdx,
        breedName  : this.breedName,
        name : this.name,
        birthday : this.birthday,
        sex : this.sex,
        weight : this.petWeight2 ? `${this.petWeight1}.${this.petWeight2}` : `${this.petWeight1}.0`,
        size : this.size,
        profile : this.profileData,
        imgName : this.imgName,
        neuter : this.neuter,
        keyword : this.keyword.length > 0 ? this.keyword.join(',') : [],
        disease : this.disease.length > 0 ? this.disease.join(',') : [],
        allergic : this.allergicYN,
        allergicInfo : this.allergicYN == 1 ? (this.allergic.length > 0 ? this.allergic.join(',') : []) : null,
      })
      if (result === 'success') this.idx === 0 ? this.registerPopup() : await this.$router.push('/mypet/')
    },
  },
}
