<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>
    <div class="popupBox w-375 "> 
      
      <div class="popupBox-title">품종 선택</div>

      <div class="brerd-select-popup">
        <h1>
          반려동물<br>품종을 선택해주세요.
        </h1>

        <div class="input-search-select">
          
          <input-field v-model="breed" :placeholder="msg" @keydown.enter="getList"/>
          <div class="icon-img icon-search" @click="getList"></div>
        </div>

        <div class="species-list">
          <div @click.prevent="check()">직접입력</div>
          <template v-for="(item,index) in getFilterList">
            <div @click.prevent="check(item.idx, item.breedName)" :key="`breedName-${index}`">{{item.breedName}}</div>
          </template>

        </div>

      </div>
      

      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">취소</button>
      </div>
    </div>


  </div>
</template>

<script>

export default {  
  props: ['pet'],
  data() {
    return {
      msg:'품종을 입력해주세요.',
      breed: null,
      breedIdx: null,
      list: [],
    }
  },
  created(){
    this.petText = this.pet == 10 ? '견' : '묘'
    this.getList()
  },
  computed: {
    getFilterList() {
      return this.breed ? this.list.filter(l => l.breedName.toString().indexOf(this.breed) > -1) : this.list
    }
  },
  methods:{
    async getList() {
      const { result, list } = await this.$api.getBreed({
        petCode  : this.pet,
        text  : this.breed,
      })
      if (result === 'success') this.list = list
    },
    close() {
      document.body.style.overflowY ="scroll"
      this.$emit('close')
    },
    check(idx, name){
      document.body.style.overflowY ="scroll"
      this.$emit('set', {idx, name})
    }
  }
}
</script>