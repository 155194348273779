<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab></mypage-tab>

    <div class="mypet-register">
      <div class="register-title">반려동물 등록</div>
      
      <validateForm ref="form">
        <template v-if="step == 1">
          <h1>어떤 아이와 함께 하시나요?</h1>
          <div class="mypet-dog-pet">
            <label v-for="item in petCodeList" :key="`pet-${item.code}`" @click="setCode('petCode', item.code)">
              <input type="radio" name="pet" :checked="item.code === petCode">
              <span><i></i></span>
              <img :src="item.src" >
              <div class="name">{{item.text}}</div>
            </label>
          </div>
                
          <div class="double-button-set">
            <button  @click="back">취소</button>
            <button :disabled="!isReady1" @click="next">다음으로(1/3)</button>
          </div>
        </template>

        <template v-if="step == 2">
          <h1>우리 아이 프로필을 입력해주세요.</h1>
              
          <div class="profile-modify">
            <div class="profile-img cursor-pointer" @click="$refs.imageInput.click()">
              <div class="">
                <image-alt :src="profileUrl" alt-type="profile"></image-alt>
              </div>
            </div>
            <button class="mypet-modify-btn"></button>
            <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="imageInput" @change="uploadImage($event)" style="display:none;">
          </div>
          <div v-if="imageMsg" class="error-message">{{ imageMsg }}</div>


          <div class="filter-title">
            <h4>{{petText}}종<span class="color-1">*</span></h4>
          </div>

          <div class="input-search-select" @click.prevent="openBreedPopup">
            <input-field v-model="breed" :placeholder="petText + msg" />
            <div class="icon-img icon-search"></div>
          </div>
          <input-field v-if="breedIdx == null" ref="breedName" v-model="breedName" placeholder="품종을 입력해주세요." :validate="rules.breedName" />

          <breed-select-popup v-if="isOpenBreedPopup" @close="closeBreedPopup" @set="setBreed" :pet="petCode" />

          <div class="filter-title">
            <h4>이름<span class="color-1">*</span></h4>
          </div>
          <input-field v-model="name" placeholder="반려동물 이름을 입력해 주세요.(15자 이내)" maxLength="15" :validate="rules.name" />

          <div class="filter-title">
            <h4>생년월일<span class="color-1">*</span></h4>
          </div>
          <input-field v-model="birthday" placeholder="숫자 8자리 입력(예: 20220101)" numberOnly maxLength="8" :validate="rules.birthday"  />

          <div class="filter-title">
            <h4>성별<span class="color-1">*</span></h4>
          </div>
          <div class="filter-list col2">
            <label v-for="item in sexList" :key="`sex-${item.code}`" @click="setCode('sex', item.code)">
              <input type="radio" name="sex" :checked="item.code === sex">
              <span><i class="icon-img icon-pet-M" :class="[{'icon-pet-M' : item.code == 'M'}, {'icon-pet-F' : item.code == 'F'}]"></i>{{item.text}}</span>
            </label>
          </div>

          <template v-if="petCode == 10">
            <div class="filter-title">
              <h4>크기<span class="color-1">*</span></h4>
            </div>
            <div class="filter-list col3">
              <label v-for="item in sizeList" :key="`size-${item.code}`" @click="setCode('size', item.code)">
                <input type="radio" name="size" :checked="item.code === size">
                <span>{{item.text}}{{petText}}</span>
              </label>
            </div>
          </template>

          <div class="filter-title">
            <h4>몸무게<span class="color-1">*</span></h4>
          </div>
          <div class="mypet-weight">
            <input-field maxLength="3" numberOnly v-model="petWeight1" class="int" />
            <span class="dot">.</span>
            <input-field maxLength="1" numberOnly v-model="petWeight2" class="decimal" />
            <span>kg</span>
          </div>

          <div class="double-button-set">
            <button  @click="back">이전</button>
            <button :disabled="!isReady2" @click="next">다음으로(2/3)</button>
        </div>
      </template>
        
      <template v-if="step === 3">
        <div class="layout-sub-body bottom-basic">
          <h1><span class="color-1">{{name}}</span>를 위한 맞춤 콘텐츠를 보고 싶다면, 추가 정보를 입력해주세요.</h1>

          <div class="filter-title between">
            <h4>중성화 여부</h4>
            <span></span>
          </div>
          <div class="filter-list col2">
            <label v-for="item in neuterList" :key="`neuter-${item.code}`" @click="setCode('neuter', item.code)">
              <input type="radio" name="neuter" :checked="item.code === neuter">
              <span>{{item.text}}</span>
            </label>
          </div>

          <div class="filter-title between">
            <h4>관심 키워드</h4>
            <span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
          </div>
          <div class="filter-list round col3">
           <label v-for="(item, index) in getKeywordCode" :key="`keyword-${index}`" >
              <input type="checkbox" name="keyword" :value="item.value" v-model="keyword">
              <span>{{item.text}}</span>
            </label>
          </div>

          <div class="filter-title between">
            <h4>관심 질환</h4>
            <span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
          </div>
          <div class="filter-list round col4">
            <label v-for="(item, index) in getDiseaseCode" :key="`disease-${index}`" >
              <input type="checkbox" name="disease" :value="item.value" v-model="disease">
              <span>{{item.text}}</span>
            </label>
          </div>

          <div class="filter-title between">
            <h4>알러지 여부</h4>
          </div>
          <div class="filter-list col2">
            <label>
              <input type="radio" value="1" v-model="allergicYN">
              <span>있어요</span>
            </label>
            <label>
              <input type="radio" value="0" v-model="allergicYN">
              <span>없어요</span>
            </label>
          </div>
          <template v-if="isAllergic">
            <div class="filter-title flat between">
              <h4></h4>
              <span class="desc"><span class="color-1">*</span> 중복선택 가능</span>
            </div>
            <div class="filter-list round col3">
              <label v-for="(item, index) in getAllergyCode" :key="`allergic-${index}`" >
                <input type="checkbox" name="allergic" :value="item.value" v-model="allergic">
                <span>{{item.text}}</span>
              </label>
            </div>
          </template>
          <div class="double-button-set">
            <button @click="back">이전</button>
            <button @click="save">등록완료</button>
          </div>

        </div>
      </template>
    </validateForm>
        
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import petMixin from "@/mixins/petMixin"
import BreedSelectPopup from "@/components/mypet/BreedSelectPopup"
export default {
  mixins: [petMixin],
  components: {BreedSelectPopup},
  name: "mypetRegister",
  data() {
    return {      
      step:1,
      idx: 0,

      petCode: null,
      petCodeList:[
        {code:10, src:require('@/assets/svg/mypet_dog.svg'), text:'강아지'},
        {code:20, src:require('@/assets/svg/mypet_cat.svg'), text:'고양이'},
      ]    
    }
  },
  computed: {
    isReady1() {
      return this.petCode
    },
    isReady2() {
      const isOkSize = (this.petCode === 10 && this.size) || this.petCode === 20 ? true : false
      const isOkBreed = this.breedIdx || (!this.breedIdx && this.breedName) ? true : false
      if (!isOkBreed) {
        return false
      }
      else if (!(this.name && this.name.patternCheck('petName'))) {
        return false
      }
      else if (!(this.birthday && this.birthday.patternCheck('yyyymmdd'))) {
        return false
      }
      else if (!(this.birthday && !this.checkBirthday(this.birthday))) {
        return false
      }
      else if (!this.sex) {
        return false
      }
      else if (!isOkSize) {
        return false
      }
      else if (!this.petWeight1 && !this.petWeight2
                || this.petWeight1 === 0 && !this.petWeight2
                || this.petWeight1 === 0 && this.petWeight2 === 0) {
        return false      
      }
      return true
    },
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    confirmRegisterPopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('cancel.mypet-register'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          this.$router.go(-1)
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    registerPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('save.mypet-register'),
        okay: 'save',
        okayCallback: async() => {
          this.setMsgPopup()
          this.$router.push('/mypet/')
        },
      })
    },
    back() {
      if (this.step > 1) {
        this.step--
      } else {
        this.confirmRegisterPopup()
      }
    },
    next() {
      this.step ++
      if(this.step == 2){
        this.petText = this.petCode == 20 ? '묘' : '견'
        this.breedIdx = 0
        this.breed = ''
        this.breedName = null 
      } 
    },
  },
}
</script>